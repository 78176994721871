import React from 'react';
import { Grid, useTheme } from '@mui/material';
import colors from '../../themes/main-colors';

interface ColumnProps {
  data: any;
  backGroundColor?: any;
  shortDesc?: any;
  header?: any;
  leftNav?: any;
}

const Column: React.FC<ColumnProps> = ({
  data,
  backGroundColor,
  shortDesc,
  header,
  leftNav,
}) => {
  const theme = useTheme()

  const getMdVal = () => {
    if (leftNav) {
      return data.length === 1 ? 12
        : 6;
    }
    else {
      return data.length === 1 ? 12
          : data.length === 2 ? 6
          : data.length === 3 ? 4
          : 3;
    }
  };

  return (
    <div className={'Column'} css={{
      flexGrow: 1,
      width: '100%',
      padding: theme.spacing(2, 0),
      borderRadius: theme.spacing(0.625),
      backgroundColor: backGroundColor
        ? backGroundColor
        : colors.white,
      '& .MuiGrid-item > h2': {
        marginTop: '0px !important',
      },
      '& .MuiGrid-spacing-xs-3': {
        margin: theme.spacing(0),
        width: '100%',
      },
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(1),
      },
    }}>
      {header && (
        <div css={{
          fontSize: theme.spacing(4.5),
          fontWeight: theme.spacing(12.5),
          color: colors.noirBlur,
          paddingLeft: theme.spacing(2.5),
          lineHeight: theme.typography.pxToRem(40),
          marginBottom: theme.spacing(2.5),
          [theme.breakpoints.down('md')]: {
            fontSize: theme.spacing(3),
            lineHeight: theme.typography.pxToRem(28),
            paddingLeft: theme.spacing(0),
            marginBottom: theme.spacing(1.25),
          },
        }}>{header}</div>
      )}
      {shortDesc && (
        <div css={{
          fontSize: theme.spacing(2.5),
          color: colors.noirBlur,
          marginTop: theme.spacing(2.5),
          marginBottom: theme.spacing(2.5),
          paddingLeft: theme.spacing(2.5),
          [theme.breakpoints.down('md')]: {
            fontSize: theme.spacing(2),
            paddingLeft: theme.spacing(0),
          },
        }}>{shortDesc}</div>
      )}
      <Grid
        container
        spacing={3}
        justifyContent="flex-start"
        css={{
          '& .pge-contenful-H2': {
            marginTop: 0,
          },
          '& .MuiGrid-spacing-xs-3': {
            marginBottom: theme.spacing(6.25),
          },
          '& .MuiGrid-item': {
            padding: theme.spacing(0, 2.5),
            [theme.breakpoints.down('md')]: {
              padding: theme.spacing(0),
            },
            '& .spacingComponent.ContentfulContentBody': {
              marginTop: theme.spacing(2),
            },
            '& .WrapperImage': {
              [theme.breakpoints.down('md')]: {
                marginTop: theme.spacing(2),
              },
              [theme.breakpoints.up('sm')]: {
                marginTop: theme.spacing(0),
              },
            },

            '& p.pge-contentful-body': {
              [theme.breakpoints.down('md')]: {
                marginTop: theme.spacing(1),
              },
            },
            '& .SocialFeed > div': {
              [theme.breakpoints.down('md')]: {
                padding: theme.spacing(2, 0),
              },
            },
          },
        }}
      >
        {data.map((comp: any, index: number) => {
          return (
            <Grid
              item
              xs={12}
              md={getMdVal()}
              key={index}
              className={`col${index + 1}`}
              css={{
                ...( !((index === 0) || (leftNav && index % 2 === 0)) ? {
                  borderLeft: '1px solid',
                  borderLeftColor: colors.lightGray1,
                  [theme.breakpoints.down('md')]: {
                    borderLeft: 'none',
                  },
                } : {})
              }}
            >
              {comp}
            </Grid>
          )
        })}
      </Grid>
    </div>
  );
};

export default Column;
